import React, {lazy, Suspense} from "react";
import {BrowserRouter, HashRouter, Route, Routes} from "react-router-dom";

const LazyComponent = (importFn: () => Promise<{ readonly default: () => JSX.Element }>) => {
    const Component = lazy(importFn);
    return <Component/>
}

export default function Router() {
    return <BrowserRouter>
        <Suspense>
            <Routes>
                <Route path="/" element={LazyComponent(() => import('./pages/'))}/>
                <Route path="/meal" element={LazyComponent(() => import('./pages/meal'))}/>
            </Routes>
        </Suspense>
    </BrowserRouter>
}